import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import { data } from '@uc-common/navbar';
import { Box, Flex } from '../components/forStartGuide/flex';
import { H2, Paragraph, Step1, Step2, Step3, track } from '../components/forStartGuide';
import { CommonLayout } from '../components/Layout/CommonLayout';

import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';

export const frontmatter = {
  title: 'Uploadcare product demo',
  description: "In this demo you'll see how to upload images, get insights, deliver files.",
};

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
  opacity: 0.5;
`;

const StepContainer = ({ active, ...props }) => (
  <Flex py={active ? 60 : 12} maxWidth={980} width="100%" flexDirection="column" {...props} />
);

const navbarData = {
  buttons: {
    defaultButtons: [
      {
        text: 'Log in',
        url: '/accounts/login/',
        handleClick: () => track('Skip'),
      },
      {
        text: 'Sign up',
        url: '/accounts/signup/',
        handleClick: () => track('Skip'),
      },
    ],
    authenticatedButton: {
      text: 'Dashboard',
      url: '/dashboard/',
      handleClick: () => track('Skip'),
    },
  },
  chapters: data.chapters,
};

const Demo = ({ location }) => {
  const [image, setImage] = useState();
  const [fileSelected, setFileSelected] = useState(false);

  useEffect(() => {
    typeof window.hj !== 'undefined' &&
      window.hj('trigger', image ? 'marketing_startguide_2' : 'marketing_startguide_1');
  }, [image]);

  return (
    <CommonLayout meta={frontmatter} pathName={location.pathname}>
      <main>
        <Navbar data={navbarData} />
        <Flex alignItems="center" flexDirection="column" px={12} pb={[60, 120]}>
          <StepContainer active>
            <Step1
              image={image}
              fileSelected={fileSelected}
              onFileSelect={(file) => {
                track('Image selected');
                setFileSelected(Boolean(file));
              }}
              onUpload={(file) => {
                track('Image uploaded');
                setImage({
                  name: file.name,
                  src: file.cdnUrl,
                  uuid: file.uuid,
                  operations: [],
                  width: file.originalImageInfo.width,
                  height: file.originalImageInfo.height,
                  size: file.size,
                  format: file.originalImageInfo.format,
                });
              }}
              onReset={() => {
                track('Reset image');
                setFileSelected(false);
                setImage(null);
              }}
            />
          </StepContainer>
          <HorizontalLine />
          <StepContainer active={Boolean(image)}>
            <Step2 image={image} />
          </StepContainer>
          <HorizontalLine />
          <StepContainer active={Boolean(image)}>
            <Step3 image={image} />
          </StepContainer>
          <HorizontalLine />
          {image && (
            <StepContainer active={Boolean(image)}>
              <Box pb={[0, 5]}>
                <Box mb={20}>
                  <H2>Ready to get started?</H2>
                </Box>
                <Paragraph>Integrating Uploadcare into your app is easy.</Paragraph>
                <Flex>
                  <Button
                    as="a"
                    href="/accounts/signup/"
                    onClick={() => track('Sign up')}
                    type={ButtonTypeEnum.PRIMARY}
                  >
                    Sign up
                  </Button>
                </Flex>
              </Box>
            </StepContainer>
          )}
        </Flex>
      </main>
      <Footer />
    </CommonLayout>
  );
};

export default Demo;
